import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { nextTick } from "vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});



router.afterEach((to) => {
  nextTick(() => {
    if (to.meta.icon) {
      const link = document.querySelector("[rel='icon']")
      link.setAttribute('href',to.meta.icon)
    }
    document.title = `${to.meta.title ?? ""} | Answer Navi`;
  });
});

export default router;
