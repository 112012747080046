import key  from "@/lib/storage/storageKey";
import useLocalStorage from "@/lib/storage/storageHelper"

const { getLocalStorage } = useLocalStorage()

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/Login.vue"),
    beforeEnter: (to, from, next) => {
      const sessionId = getLocalStorage(key.sessionId);
      if (sessionId) next({ path: "/search/input" });
      next();
    },
    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("@/views/Login/Login.vue"),
      },
    ]
  },
  {
    path: "/search",
    name: "SearchScreen",
    redirect: "/search/input",
    component: () => import("@/views/layouts/HeaderLayout.vue"),
    beforeEnter: (to, from, next) => {
      const sessionId = to.query.session_state ?? getLocalStorage(key.sessionId);
      if (sessionId) next();
      next({ path: "/login" });
    },
    children: [
      {
        path: "/search/input",
        name: "SearchScreenInput",
        meta: { title: "検索条件入力", icon: "/search.ico" },
        component: () => import("@/views/SearchInput/SearchInput.vue"),
      },
      // {
      //   path: "/search/input/method",
      //   name: "SearchScreenInputMethod",
      //   component: () => import("@/views/Method/Method.vue"),
      // },
    ],
  },
  {
    path: "/result",
    name: "ResultScreen",
    redirect: "/search/result",
    component: () => import("@/views/layouts/HeaderLayout.vue"),
    beforeEnter: (to, from, next) => {
      const sessionId = to.query.session_state ?? getLocalStorage(key.sessionId);
      if (sessionId) next();
      next({ path: "/login" });
    },
    children: [
      {
        path: "/search/result/ai",
        name: "AISearchScreenResult",
        meta: { title: "結果", icon: "/result.ico" },
        component: () => import("@/views/SearchResult/AISearchResult.vue"),
      },
      {
        path: "/search/result/keyword",
        name: "KeywordSearchScreenResult",
        meta: { title: "結果", icon: "/result.ico" },
        component: () => import("@/views/SearchResult/KWSearchResult.vue"),
      },
      // {
      //   path: "/search/result/method",
      //   name: "SearchScreenResultMethod",
      //   component: () => import("@/views/Method/Method.vue"),
      // },
      {
        path: "details",
        name: "SearchDetail",
        meta: { title: "詳細", icon: "/detail.ico" },
        component: () => import("@/views/SearchDetail/SearchDetail.vue"),
      },
    ],
  },
  { path: "/:pathMatch(.*)", redirect: "/login" },
];

export default routes
