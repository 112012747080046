export default function useLocalStorage() {
  const addLocalStorage = (key, value) => {
		localStorage.setItem(key, value);
		return true;
	}

  const getLocalStorage = (key) => {
		return localStorage.getItem(key);
	}

	const removeLocalStorage = (key) => {
		localStorage.removeItem(key);
		return true;
	}

	const clearLocalStorage = () => {
		localStorage.clear();
		return true;
	}

  return { addLocalStorage, getLocalStorage, removeLocalStorage, clearLocalStorage }
}